export default [
  {
    title: 'clients.title',
    icon: 'UsersIcon',
    route: 'clients-list',
    action: 'view_all',
    resource: 'clients',
  },
  {
    title: 'projects.title',
    icon: 'ClipboardIcon',
    route: 'projects-list',
    action: 'view_own',
    resource: 'projects',
  },
  {
    title: 'tasks.title',
    icon: 'CheckSquareIcon',
    route: 'tasks-list',
    action: 'view_own',
    resource: 'tasks',
  },
  {
    title: 'maintenances.title',
    icon: 'ShieldIcon',
    route: 'maintenances-list',
    action: 'view_own',
    resource: 'maintenances',
  },
  {
    title: 'users.title',
    icon: 'TruckIcon',
    route: 'users-list',
    action: 'view_all',
    resource: 'users',
  },
  {
    title: 'issues.title',
    icon: 'ToolIcon',
    // route: 'issues-list',
    action: 'view_own',
    resource: 'issues',
    route: null,
    disabled: true,
  },
  {
    title: 'materials.title',
    icon: 'TriangleIcon',
    action: 'view_all',
    resource: 'materials',
    route: 'materials-list',
  },
  {
    title: 'evostore.title',
    icon: 'ShoppingBagIcon',
    action: 'read',
    resource: 'general',
    route: 'evostore',
  },
  {
    title: 'integrations.title',
    icon: 'AirplayIcon',
    action: 'read',
    resource: 'general',
    route: null,
    disabled: true,
  },
  {
    title: 'calendar.title',
    icon: 'CalendarIcon',
    action: 'read',
    resource: 'general',
    route: null,
    disabled: true,
  },
  {
    title: 'services.title',
    icon: 'LayersIcon',
    action: 'read',
    resource: 'general',
    route: null,
    disabled: true,
  },
  {
    title: 'statistic.title',
    icon: 'BarChart2Icon',
    action: 'read',
    resource: 'general',
    route: null,
    disabled: true,
  },
  {
    title: 'invoices.title',
    icon: 'FilePlusIcon',
    action: 'read',
    resource: 'general',
    route: null,
    disabled: true,
  },
  {
    title: 'budgets.title',
    icon: 'FileTextIcon',
    action: 'read',
    resource: 'general',
    route: null,
    disabled: true,
  },
  {
    title: 'settings.title',
    icon: 'SettingsIcon',
    resource: 'admin',
    action: 'edit_all',
    route: 'tenant-settings',
    disabled: false,
  },
]
