<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <b-badge variant="primary"
               class="badge-glow mr-1">
      BETA
    </b-badge><b>v.0.9.8</b> © 2022
      <b-link
        class="ml-25"
        href="https://evorgy.com"
        target="_blank"
      >Evorgy S.L.</b-link>
      <span class="d-none d-sm-inline-block">, Todos los derechos reservados. · Made in Zaragoza, Spain</span>
    </span>

    <span class="float-md-right d-none d-md-block">
    </span>
  </p>
</template>

<script>
import { BLink, BBadge } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BBadge,
  },
}
</script>
