import store from '@/store'
import { ref, computed } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import i18n from '@/libs/i18n'

export default function useProfileSettings() {
  const toast = useToast()

  const userPersonalData = ref(null)

  userPersonalData.value = computed(() => JSON.parse(localStorage.getItem('userData')))

  const changePassword = params => store.dispatch('model/profile/changePassword', params)
    .then(response => response)
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('users.password_not_changed'),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })

      return error
    })

  const savePersonalData = formData => {
    const params = new FormData()

    params.append('address1', formData.userData.address1)
    params.append('address2', formData.userData.address2)
    params.append('city', formData.userData.city)
    params.append('legal_id', formData.userData.legal_id)
    params.append('name', formData.userData.name)
    params.append('postal_code', formData.userData.postal_code)
    params.append('state', formData.userData.state)
    params.append('surname', formData.userData.surname)
    params.append('telephone', formData.userData.telephone)
    params.append('uuid', formData.userData.uuid)
    params.append('newAvatar', formData.newAvatar)

    const res = store.dispatch('model/profile/savePersonalData', params)
      .then(response => {
        const currentSlug = localStorage.getItem('currentSlug')

        // eslint-disable-next-line no-param-reassign
        formData.userData.avatar = `${process.env.VUE_APP_API_URL}/api/v1/${currentSlug}/user-avatar/${formData.userData.uuid}/${response.data}`
        localStorage.setItem('userData', JSON.stringify(formData.userData))

        const { user } = store.state.auth
        user.avatar = `${process.env.VUE_APP_API_URL}/api/v1/${currentSlug}/user-avatar/${formData.userData.uuid}/${response.data}`
        store.commit('auth/SET_USER', user)

        return response
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('users.update_failed'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return error
      })

    return res
  }

  const delay = milliseconds => new Promise(resolve => setTimeout(resolve, milliseconds))

  return {
    userPersonalData,
    changePassword,
    savePersonalData,
    delay,
  }
}
