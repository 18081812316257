<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notifications.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t("Notifications") }}
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ notifications.length }}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      ref="notification"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <transition-group
        tag="div"
        enter-active-class="animated fadeInLeft"
        leave-active-class="animated fadeOutLeft"
      >

        <!-- System Notifications -->
        <b-link
          v-for="(notification, index) in notifications"
          :key="notification.id"
          class="list-item"
        >
          <b-media>
            <template #aside>
              <b-badge
                variant="light-primary"
                class="mr-1"
                @click="removeNotification(index, notification)"
              >
                <feather-icon
                  icon="DeleteIcon"
                />
              </b-badge>
              <b-avatar
                size="22"
                :variant="resolveAvatarVariant(notification.priority)"
              >
                <feather-icon :icon="resolveIcon(notification.priority)" />
              </b-avatar>
            </template>
            <p class="media-heading">
              <span class="font-weight-bolder">
                {{ notification.type }}
              </span>
            </p>
            <small class="notification-text">{{ notification.alert }}</small>
          </b-media>
        </b-link>
      </transition-group>
    </vue-perfect-scrollbar>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import {
  ref, onMounted, onUpdated, onUnmounted, computed,
} from '@vue/composition-api'
import axios from '@axios'
import store from '@/store'
import useGeneralView from "@/views/useGeneralView"

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { notificationsData, fetchNotifications, readNotifications } = useGeneralView()

    onMounted(() => {
      fetchNotifications()
    })

    const notifications = computed(() => notificationsData.value)

    const systemNotifications = []

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }
    const resolveAvatarVariant = priority => {
      switch (priority) {
        case 'info':
          return 'primary'
        case 'warning':
          return 'warning'
        case 'danger':
          return 'danger'
        case 'Green':
          return 'success'
        default:
          return 'secondary'
      }
    }

    const resolveIcon = priority => {
      switch (priority) {
        case 'info':
          return 'InfoIcon'
        case 'warning':
          return 'AlertTriangleIcon'
        case 'danger':
          return 'AlertCircleIcon'
        case 'Green':
          return 'InfoIcon'
        default:
          return 'BellIcon'
      }
    }

    const removeNotification = (index, notification) => {
      readNotifications({ index, data: notification })
          .then(response => response)
          .catch(error => {
            if (error.status === 404) {
              //
            }
          })
    }



    return {
      removeNotification,
      notifications,
      resolveAvatarVariant,
      resolveIcon,
      systemNotifications,
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>
.list-item {
  display: inline-block;
  width: 100%;
}
.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

</style>
