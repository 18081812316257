import {
    ref, reactive, watch, computed,
} from '@vue/composition-api'
import { format, parse } from 'date-fns'
import store from '@/store'
import router from '@/router'

export default function useGeneralView() {
    // NOTIFICATIONS
    const notificationsData = computed(() => store.state.general.notifications)
    const fetchNotifications = async params => {
        await store.dispatch('model/general/fetchNotifications', params)
            .then(response => {
                store.commit('general/setNotifications', response.data)
            })
            .catch(error => {
                if (error.response.status === 404) {
                    store.commit('general/setNotifications', null)
                }
            })
    }

    const readNotifications = async params => {
        await store.dispatch('model/general/readNotifications', params.data)
            .then(response => {
                store.commit('general/deleteNotifications', params.index)
            })
            .catch(error => {
                if (error.response.status === 404) {
                }
            })
    }

    // SEARCH
    const fetchSearch = params => store.dispatch('model/general/fetchSearch', params)
            .then(response => response.data)
            .catch(error => error.response)

    const dateFormat = (date, formatIn = 'yyyy-MM-dd', formatOut = 'dd/MM/yyyy') => {
        if (date) {
            const d = parse(date, formatIn, new Date())
            const dateF = format(d, formatOut)
            return dateF
        } else {
            return date
        }
    }

    return {
        fetchNotifications,
        notificationsData,
        readNotifications,
        fetchSearch,
        dateFormat,
    }
}
